/* eslint-disable jsx-a11y/anchor-has-content */
/* eslint-disable jsx-a11y/anchor-is-valid */
import { useEffect } from "react";
import { Link } from "react-router-dom";

const AboutUs = () => {
  useEffect(() => {
    document.title = "About Us - Link Jobs";
  }, []);

  return (
    <>
      <main className="main">
        <section className="section-box">
          <div className="breacrumb-cover bg-img-about">
            <div className="container">
              <div className="row">
                <div className="col-lg-6">
                  <h2 className="mb-10">About Us</h2>
                  <p className="font-lg color-text-paragraph-2">
                    Get to know us
                  </p>
                </div>
                <div className="col-lg-6 text-lg-end">
                  <ul className="breadcrumbs mt-40">
                    <li>
                      <Link className="home-icon" to="/">
                        Home
                      </Link>
                    </li>
                    <li>About Us</li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section className="section-box mt-50">
          <div className="post-loop-grid">
            <div className="container">
              <div className="text-center">
                <h6 className="f-18 color-text-mutted text-uppercase">
                  Our company
                </h6>
                <h2 className="section-title mb-10 wow animate__animated animate__fadeInUp">
                  About Our Company
                </h2>
                <p className="font-sm color-text-paragraph wow animate__animated animate__fadeInUp w-lg-50 mx-auto">
                  Welcome to Link Jobs, where talent meets opportunity. As a
                  leading force in the realm of job recruitment, we are
                  dedicated to connecting exceptional talent with remarkable
                  opportunities that propel both careers and businesses forward.
                </p>
              </div>
              <div className="row mt-70">
                <div className="col-lg-6 col-md-12 col-sm-12">
                  <img
                    src="https://external-content.duckduckgo.com/iu/?u=https%3A%2F%2Fcms.qz.com%2Fwp-content%2Fuploads%2F2015%2F11%2Fworking-men.jpg%3Fquality%3D75%26strip%3Dall%26w%3D1380%26h%3D778&f=1&nofb=1&ipt=bb6ace88f0814a8169ca2b07be6df92d9d0129b9e7fa60196a3ec66f7b09101c&ipo=images"
                    alt="joxBox"
                  />
                </div>
                <div className="col-lg-6 col-md-12 col-sm-12">
                  <h3 className="mt-15">What we can do?</h3>
                  <div className="mt-20">
                    <p className="font-md color-text-paragraph mt-20">
                      <b>Our Mission:</b> <br />
                      At Link Jobs, we are driven by a singular mission — to
                      bridge the gap between employers seeking the brightest
                      minds and individuals aspiring to carve a fulfilling
                      career path. We believe in fostering growth, cultivating
                      talent, and creating lasting partnerships.
                    </p>
                    <p className="font-md color-text-paragraph mt-20">
                      Link Jobs is a premier job recruiting company dedicated to
                      connecting exceptional talent with rewarding
                      opportunities. Our mission is to bridge the gap between
                      employers and individuals, fostering growth and creating
                      lasting partnerships. With a seasoned team, personalized
                      approach, and cutting-edge technology, we cater to the
                      unique needs of both job seekers and employers. Whether
                      you're seeking the ideal position or top-tier talent,
                    </p>
                    <p className="font-md color-text-paragraph mt-20">
                      Link Jobs is your strategic partner for success. Join us
                      on a journey of growth and explore exciting career
                      opportunities or build a workforce that propels your
                      company to new heights.
                    </p>
                  </div>
                  <div className="mt-30">
                    <Link className="btn btn-brand-1" to="/contact-us">
                      Contact Us
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </main>
    </>
  );
};

export default AboutUs;
