import { NavLink, Link } from "react-router-dom";
const Header = () => {
    return (
      <>
        <header className="header sticky-bar sticky-top bg-white">
          <div className="container">
            <div className="main-header">
              <div className="header-left">
                <div className="header-logo">
                  <a className="d-flex" href="index.html">
                    <img alt="jobBox" src="assets/imgs/logo.png" />
                  </a>
                </div>
              </div>
              <div className="header-nav">
                <nav className="nav-main-menu">
                  <ul className="main-menu">
                    <li className="has-no-children">
                      <NavLink to="/">
                        Home
                      </NavLink>
                    </li>
                    <li className="has-no-children">
                      <NavLink to="/jobs">Find a Job</NavLink>
                    </li>
                    <li className="has-no-children">
                      <NavLink to="/recruiters">Recruiters</NavLink>
                    </li>
                    <li className="has-no-children">
                      <NavLink to="/about-us">About Us</NavLink>
                    </li>
                    <li className="has-no-children">
                      <NavLink to="/contact-us">Contact Us</NavLink>
                    </li>
                   
                    {/* <li className="has-no-children">
                      <NavLink to="/blog">Blog</NavLink>

                    </li> */}
                   
                  </ul>
                </nav>
                <div className="burger-icon burger-icon-white">
                  <span className="burger-icon-top"></span>
                  <span className="burger-icon-mid"></span>
                  <span className="burger-icon-bottom"></span>
                </div>
              </div>
              <div className="header-right">
                <div className="block-signin">
                  <Link
                    className="text-link-bd-btom hover-up"
                    to="register"
                  >
                    Register
                  </Link>
                  <Link
                    className="btn btn-default btn-shadow ml-40 hover-up"
                    to="/login"
                  >
                    Login
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </header>
      </>
    );
}
 
export default Header;