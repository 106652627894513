import { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { useForm } from "react-hook-form";
import axios from "../../config/axios";
import toast, { Toaster } from "react-hot-toast";
import useAuth from "middlewares/hooks/useAuth";

const Register = () => {
  useEffect(() => {
    document.title = "Register - Link Jobs";
  }, []);
    const [disabled, setDisabled] = useState(false);
    const nagivate = useNavigate();
    const { setAuth } = useAuth();
    const {
      register,
      handleSubmit,
      getValues,
      watch,
      formState: { errors },
    } = useForm();

    const onSubmit = async (data) => {
      try {
        setDisabled(true);
        const response = await axios.post("/register/user", data);
        const token = response?.data.data.token.code;
        setAuth({ token });
        nagivate("/register/verify");
      } catch (error) {
        if (!error?.response) {
          toast("No server response");
          setDisabled(false);
        } else {
          setDisabled(false);
          toast(error?.response?.data?.messages);
        }
      }
    }; 
   
  return (
    <>
      <Toaster />
      <main className="main">
        <section className="login-register">
          <div className="container">
            <div className="row login-register-cover">
              <div className="col-lg-4 col-md-6 col-sm-12 mx-auto">
                <div className="text-center">
                  {/* <p className="font-sm text-brand-2">Register </p> */}
                  <h2 className="mt-10 mb-5 text-brand-1">
                    Start for free Today
                  </h2>
                  <p className="font-sm text-muted mb-30">
                    Access to all features. No payment required.
                  </p>
                </div>
                <form
                  className="login-register text-start mt-0"
                  autoComplete="off"
                  onSubmit={handleSubmit(onSubmit)}
                >
                  <div className="form-group">
                    <label className="form-label" for="input-1">
                      Full Name *
                    </label>
                    <input
                      className="form-control"
                      id="fullname"
                      type="text"
                      required=""
                      name="fullname"
                      placeholder="John Doe"
                      autoComplete="off"
                      {...register("fullname", { required: true })}
                    />
                    {errors?.fullname?.type === "required" && (
                      <p className="text-small text-danger help-block">
                        Please enter your Fullname
                      </p>
                    )}
                  </div>
                  <div className="form-group">
                    <label className="form-label" for="input-1">
                      Email Address *
                    </label>
                    <input
                      className="form-control"
                      id="email"
                      type="email"
                      required=""
                      name="email"
                      placeholder="johndoe@example.com"
                      autoComplete="off"
                      {...register("email", {
                        required: true,
                        pattern: { value: /^\S+@\S+$/i },
                      })}
                    />
                    {errors?.email?.type === "required" && (
                      <p className="text-small  m-0 text-danger help-block">
                        Please provide a valid Email Address
                      </p>
                    )}
                    {errors?.email?.type === "pattern" && (
                      <p className="text-small m-0 text-danger help-block">
                        Please provide a valid Email Address
                      </p>
                    )}
                  </div>
                  <div className="form-group">
                    <label className="form-label" for="input-1">
                      Contact *
                    </label>
                    <input
                      className="form-control"
                      id="contact"
                      type="tel"
                      required=""
                      name="contact"
                      placeholder="075*** or 25675***"
                      autoComplete="off"
                      {...register("contact", { required: true })}
                    />
                    {errors?.contact?.type === "required" && (
                      <p className="text-small text-danger help-block">
                        Please enter your contact
                      </p>
                    )}
                  </div>
                  <div className="form-group">
                    <label className="form-label" for="input-4">
                      Password *
                    </label>
                    <input
                      className="form-control"
                      id="password"
                      type="password"
                      required=""
                      name="password"
                      placeholder="************"
                      autoComplete="off"
                      {...register("password", { required: true })}
                    />
                    {errors?.password?.type === "required" && (
                      <p className="text-small  text-danger help-block">
                        Please provide a password
                      </p>
                    )}
                  </div>
                  <div className="form-group">
                    <label className="form-label" for="input-4">
                      Confirm Password *
                    </label>
                    <input
                      className="form-control"
                      id="confirm-password"
                      type="password"
                      required=""
                      name="password"
                      placeholder="************"
                      autoComplete="off"
                      {...register("password_confirmation", {
                        required: true,
                        validate: (value) => {
                          return value === watch("password") || false;
                        },
                      })}
                    />
                    {watch("password_confirmation") !== watch("password") &&
                    getValues("password_confirmation") ? (
                      <p className="text-small text-danger help-block">
                        Password not match
                      </p>
                    ) : null}
                    {errors?.password_confirmation?.type === "required" && (
                      <p className="text-small text-danger help-block">
                        Please confirm password
                      </p>
                    )}
                  </div>
                  <div className="login_footer form-group d-flex justify-content-between">
                    <label className="cb-container">
                      <input type="checkbox" defaultChecked />
                      <span className="text-small">
                        I agree to the terms and conditions
                      </span>
                      <span className="checkmark"></span>
                    </label>
                  </div>
                  <div className="form-group">
                    <button
                      className="btn btn-brand-1 hover-up w-100"
                      type="submit"
                      name="login"
                      disabled={disabled}
                    >
                      {!disabled && "Create Acccount"}{" "}
                      {disabled && "Please Wait ....."}
                    </button>
                  </div>
                  <div className="text-muted text-center">
                    Have an Account Already? <Link to="/login">Login</Link>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </section>
      </main>
    </>
  );
};

export default Register;
