/* eslint-disable jsx-a11y/anchor-has-content */
/* eslint-disable jsx-a11y/anchor-is-valid */
import { useEffect } from "react";
import { keepPreviousData, useQuery } from "@tanstack/react-query";
import axios from "config/axios";
import Loader from "services/loaders/loader";
import { Link } from "react-router-dom";
const Recruiters = () => {
  useEffect(() => {
    document.title = "Recruiters - Link Jobs";
  }, []);
  const { data: data_jobs, isLoading: isLoading_jobs } = useQuery({
    queryKey: ["company-data"],
    queryFn: async () => {
      const fetchURL = `/company`;

      try {
        const response = await axios.get(fetchURL);
        return response.data.data.company;
      } catch (error) {
        console.error("Axios request failed", error);
        throw error;
      }
    },
    placeholderData: keepPreviousData, // don't go to 0 rows when refetching or paginating to the next page
  });
  return (
    <>
      <main className="main">
        <section className="section-box-2">
          <div className="container">
            <div className="banner-hero banner-single banner-single-bg">
              <div className="block-banner text-center">
                <h3 className="wow animate__animated animate__fadeInUp">
                  <span className="color-brand-2">Many Recruiters</span>{" "}
                  Available
                </h3>
                <div
                  className="font-sm color-text-paragraph-2 mt-10 wow animate__animated animate__fadeInUp"
                  data-wow-delay=".1s"
                ></div>
                <div
                  className="form-find text-start mt-40 wow animate__animated animate__fadeInUp"
                  data-wow-delay=".2s"
                >
                  <form>
                    <input
                      className="form-input input-keysearch mr-10"
                      type="text"
                      placeholder="Your keyword... "
                    />
                    <button className="btn btn-default btn-find font-sm">
                      Search
                    </button>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section className="section-box mt-30">
          <div className="container">
            <div className="row flex-row-reverse">
              <div className="col-lg-9 col-md-12 col-sm-12 col-12 float-right">
                <div className="content-page">
                  <div className="row">
                    {isLoading_jobs && <Loader />}
                    {data_jobs?.map((company) => (
                      <div
                        className="col-xl-4 col-lg-4 col-md-6 col-sm-12 col-12"
                        key={company?.id}
                      >
                        <div className="card-grid-1 hover-up wow animate__animated animate__fadeIn">
                          <div className="image-box">
                            <Link to="/">
                              <img
                                src="assets/imgs/brands/brand-1.png"
                                alt="jobBox"
                              />
                            </Link>
                          </div>
                          <div className="info-text mt-10">
                            <h5 className="font-bold">
                              <a href="#">{company?.title}</a>
                            </h5>

                            <div className="mt-30">
                              <a
                                className="btn btn-grey-big"
                                href="jobs-grid.html"
                              >
                                <span>{company?.jobs}</span>
                                <span> Jobs Open</span>
                              </a>
                            </div>
                          </div>
                        </div>
                      </div>
                    ))}
                  </div>
                </div>
                <div className="paginations">
                  <ul className="pager">
                    <li>
                      <a className="pager-prev" href="#"></a>
                    </li>
                    <li>
                      <a className="pager-next" href="#"></a>
                    </li>
                  </ul>
                </div>
              </div>
              <div className="col-lg-3 col-md-12 col-sm-12 col-12">
                <div className="sidebar-shadow none-shadow mb-30">
                  <div className="sidebar-filters">
                    <div className="filter-block head-border mb-30">
                      <h5>
                        Filter{" "}
                        <a className="link-reset" href="#">
                          Reset
                        </a>
                      </h5>
                    </div>
                    <div className="filter-block mb-20">
                      <h5 className="medium-heading mb-15">Industry</h5>
                      <div className="form-group">
                        <ul className="list-checkbox">
                          <li>
                            <label className="cb-container">
                              <input type="checkbox" checked="checked" />
                              <span className="text-small">All</span>
                              <span className="checkmark"></span>
                            </label>
                            <span className="number-item">180</span>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </main>
    </>
  );
};

export default Recruiters;
