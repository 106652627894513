import './App.css';
import { Route, Routes } from "react-router-dom";
import Layout from "layouts/layout";
import Missing from "layouts/404";
import Home from 'pages/home/home';
import LandingPageLayout from 'layouts/landing-page-layout';
import FindJobs from 'pages/home/findjobs';
import Recruiters from 'pages/home/recuriter';
import AboutUs from 'pages/home/about-us';
import ContactUs from 'pages/home/contact-us';
import Login from 'pages/auth/login';
import Register from 'pages/auth/register';
import Verify from 'pages/auth/verify';
import Dashboard from 'pages/dashboard/dashboard';
import RequiredAuth from "middlewares/hooks/requiredLogin";
import PersitLogin from "middlewares/global/persistentLogin";
function App() {
  return (
    <>
      <Routes>
        <Route path="" element={<Layout />}>
          <Route element={<LandingPageLayout />}>
            <Route path="/" index element={<Home />} />
            <Route path="/jobs" element={<FindJobs />} />
            <Route path="/recruiters" element={<Recruiters />} />
            <Route path="/about-us" element={<AboutUs />} />
            <Route path="/contact-us" element={<ContactUs />} />
          </Route>
          <Route path="/login" element={<Login />} />
          <Route path="/register" element={<Register />} />
          <Route path="/register/verify" element={<Verify />} />
          <Route element={<PersitLogin />}>
            <Route element={<RequiredAuth />}>

          <Route path="/dashboard" element={<Dashboard />} />
            </Route>
          </Route>

          <Route path="*" element={<Missing />} />
        </Route>
      </Routes>
    </>
  );
}

export default App;
