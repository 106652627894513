import { useEffect } from "react";
import { Link } from "react-router-dom";
import { keepPreviousData, useQuery } from "@tanstack/react-query";
import axios from "config/axios";
import Loader from "services/loaders/loader";

const Home = () => {
  useEffect(() => {
    document.title = "Home - Link Jobs";
  }, []);

  const { data, isLoading } = useQuery({
    queryKey: ["category-data"],
    queryFn: async () => {
      const fetchURL = `/categories`;

      try {
        const response = await axios.get(fetchURL);
        return response.data.data.categories;
      } catch (error) {
        console.error("Axios request failed", error);
        throw error;
      }
    },
    placeholderData: keepPreviousData, // don't go to 0 rows when refetching or paginating to the next page
  });

  const { data: data_jobs, isLoading: isLoading_jobs } = useQuery({
    queryKey: ["jobs-data"],
    queryFn: async () => {
      const fetchURL = `/jobs`;

      try {
        const response = await axios.get(fetchURL);
        return response.data.data.jobs;
      } catch (error) {
        console.error("Axios request failed", error);
        throw error;
      }
    },
    placeholderData: keepPreviousData, // don't go to 0 rows when refetching or paginating to the next page
  });
   const { data: data_city, isLoading: isLoading_city } = useQuery({
     queryKey: ["city-data"],
     queryFn: async () => {
       const fetchURL = `/cities`;

       try {
         const response = await axios.get(fetchURL);
         return response.data.data.cities;
       } catch (error) {
         console.error("Axios request failed", error);
         throw error;
       }
     },
     placeholderData: keepPreviousData, // don't go to 0 rows when refetching or paginating to the next page
   });

  return (
    <>
      <main className="main">
        <div className="bg-homepage1"></div>
        <section className="section-box">
          <div className="banner-hero hero-1">
            <div className="banner-inner">
              <div className="row">
                <div className="col-xl-8 col-lg-12">
                  <div className="block-banner">
                    <h1 className="heading-banner wow animate__animated animate__fadeInUp">
                      The <span className="color-brand-2">Easiest Way</span>
                      <br className="d-none d-lg-block" />
                      to Get Your New Job
                    </h1>
                    <div
                      className="banner-description mt-20 wow animate__animated animate__fadeInUp"
                      data-wow-delay=".1s"
                    >
                      Each month, more than 3 million job seekers turn to{" "}
                      <br className="d-none d-lg-block" />
                      website in their search for work, making over 140,000{" "}
                      <br className="d-none d-lg-block" />
                      applications every single day
                    </div>
                  </div>
                </div>
                <div className="col-xl-4 col-lg-12 d-none d-xl-block col-md-6">
                  <div className="banner-imgs">
                    <div className="block-1 shape-1">
                      <img
                        className="img-responsive"
                        alt="jobBox"
                        src="https://external-content.duckduckgo.com/iu/?u=https%3A%2F%2Fweetracker.com%2Fwp-content%2Fuploads%2F2020%2F03%2Ftech-startups-1200x640-1.jpg&f=1&nofb=1&ipt=9b241ba70998999172a475b18adf886449b55d4f4bfd358ba9b5ed1334f3752d&ipo=images"
                      />
                    </div>
                    <div className="block-2 shape-2">
                      <img
                        className="img-responsive"
                        alt="jobBox"
                        src="https://external-content.duckduckgo.com/iu/?u=https%3A%2F%2Fwww.giz.de%2Fstatic%2Fen%2Fmedia%2FE4D_Elektrikerin_815x375_header_.jpg&f=1&nofb=1&ipt=d9b663a69836742aa91c1052b643e51b9ad641f46bcad4ac27b23b97efb55fed&ipo=images"
                      />
                    </div>
                    <div className="block-3 shape-3">
                      <img
                        className="img-responsive"
                        alt="jobBox"
                        src="assets/imgs/page/homepage1/icon-top-banner.png"
                      />
                    </div>
                    <div className="block-4 shape-3">
                      <img
                        className="img-responsive"
                        alt="jobBox"
                        src="assets/imgs/page/homepage1/icon-bottom-banner.png"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <div className="mt-100"></div>
        <section className="section-box mt-80">
          <div className="section-box wow animate__animated animate__fadeIn">
            <div className="container">
              <div className="text-center">
                <h2 className="section-title mb-10 wow animate__animated animate__fadeInUp">
                  Browse by category
                </h2>
                <p className="font-lg color-text-paragraph-2 wow animate__animated animate__fadeInUp">
                  Find the job that&rsquo;s perfect for you. about 800+ new jobs
                  everyday
                </p>
              </div>
              <div className="mt-50">
                <div className="container">
                  <div className="row pb-70 pt-5">
                    {isLoading && <Loader />}
                    {data?.map((category) => (
                      <div
                        className="col-md-3 hover-up mb-2"
                        key={category?.id}
                      >
                        <Link to="/jobs" className="card job-category-card">
                          <div className="card-body">
                            <div className="item-logo">
                              <div className="image-left">
                                <img
                                  alt="noImage"
                                  src={`https://jobs.linkdates.net/images/${category.image}`}
                                  height="50px"
                                />
                              </div>
                            </div>
                            <div className="text-info-right">
                              <h4 className="card-title">{category.title}</h4>
                              {/* <p className="card-text font-xs">
                                <span className="text-muted">Explore</span> 1526 Jobs
                                Available
                              </p> */}
                            </div>
                          </div>
                        </Link>
                      </div>
                    ))}
                  </div>
                </div>
                <div className="swiper-button-next"></div>
                <div className="swiper-button-prev"></div>
              </div>
            </div>
          </div>
        </section>
        <div className="section-box mb-30">
          <div className="container">
            <div className="box-we-hiring">
              <div className="text-1">
                <span className="text-we-are">We are</span>
                <span className="text-hiring">Hiring</span>
              </div>
              <div className="text-2">
                Let&rsquo;s <span className="color-brand-1">Work</span> Together
                <br /> &amp; <span className="color-brand-1">Explore</span>{" "}
                Opportunities
              </div>
              <div className="text-3">
                <Link className="btn btn-apply btn-apply-icon" to="/jobs">
                  Apply now
                </Link>
              </div>
            </div>
          </div>
        </div>
        <section className="section-box mt-50">
          <div className="container">
            <div className="text-center">
              <h2 className="section-title mb-10 wow animate__animated animate__fadeInUp">
                Jobs of the day
              </h2>
            </div>
            <div className="mt-70">
              <div className="row">
                {isLoading_jobs && <Loader />}
                {data_jobs?.slice(0, 8)?.map((job) => (
                  <div
                    className="col-xl-3 col-lg-4 col-md-6 col-sm-12 col-12"
                    key={job?.id}
                  >
                    <div className="card-grid-2 hover-up">
                      <div className="card-grid-2-image-left">
                        <span className="flash"></span>
                      </div>
                      <div className="card-block-info">
                        <h6>
                          <Link to="/login">{job?.title}</Link>
                        </h6>
                        <div className="mt-5">
                          <span className="card-briefcase">Fulltime</span>
                        </div>
                        <p className="font-sm color-text-paragraph mt-15">
                          {job?.designation}
                        </p>
                        <div className="card-2-bottom mt-30">
                          <div className="row">
                            <div className="col-lg-6 col-6">
                              <span className="card-text-pricetext-muted">
                                Salary <br />
                              </span>
                              <span className="card-text-price text-small">
                                {job?.salary}
                              </span>
                            </div>
                            <div className="col-lg-5 col-5 text-end">
                              <Link className="btn btn-apply-now" to="/login">
                                Apply now
                              </Link>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </div>
        </section>
        <section className="section-box overflow-visible mt-100 mb-100">
          <div className="container">
            <div className="row">
              <div className="col-lg-6 col-sm-12">
                <div className="box-image-job">
                  <figure className="wow animate__animated animate__fadeIn">
                    <img
                      alt="jobBox"
                      src="https://external-content.duckduckgo.com/iu/?u=https%3A%2F%2Fimages.theconversation.com%2Ffiles%2F267038%2Foriginal%2Ffile-20190402-177167-1oy3xvj.jpg%3Fixlib%3Drb-1.1.0%26q%3D45%26auto%3Dformat%26w%3D926%26fit%3Dclip&f=1&nofb=1&ipt=98015cdb38630d9e58f6eef68c4b92d296e170a9b77e71bb53180ff6527c719f&ipo=images"
                    />
                  </figure>
                </div>
              </div>
              <div className="col-lg-6 col-sm-12">
                <div className="content-job-inner">
                  <span className="color-text-mutted text-32">
                    Millions Of Jobs.{" "}
                  </span>
                  <h2 className="text-52 wow animate__animated animate__fadeInUp">
                    Find The One That&rsquo;s{" "}
                    <span className="color-brand-2">Right</span> For You
                  </h2>
                  <div className="mt-40 pr-50 text-md-lh28 wow animate__animated animate__fadeInUp">
                    Search all the open positions on the web. Get your own
                    personalized salary estimate. Read reviews on over 600,000
                    companies worldwide. The right job is out there.
                  </div>
                  <div className="mt-40">
                    <div className="wow animate__animated animate__fadeInUp">
                      <Link className="btn btn-default" to="/jobs">
                        Search Jobs
                      </Link>
                      <Link className="btn btn-link" to="/about-us">
                        Learn More
                      </Link>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section className="section-box mt-50">
          <div className="container">
            <div className="text-center">
              <h2 className="section-title mb-10 wow animate__animated animate__fadeInUp">
                Locations
              </h2>
              <p className="font-lg color-text-paragraph-2 wow animate__animated animate__fadeInUp">
                Find your favourite jobs and get the benefits of yourself
              </p>
            </div>
          </div>
          <div className="container">
            <div className="row mt-50">
              {isLoading_city && <Loader />}
              {data_city?.map((city) => (
                <div
                  className="col-xl-3 col-lg-3 col-md-5 col-sm-12 col-12"
                  key={city?.id}
                >
                  <div className="card-image-top hover-up">
                    <div className="informations">
                      <Link to="/jobs">
                        <h5 className="text-center p-2">{city?.title}</h5>
                      </Link>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </section>

        <section className="section-box mt-50 mb-20">
          <div className="container">
            <div className="box-newsletter">
              <div className="row">
                <div className="col-xl-3 col-12 text-center d-none d-xl-block">
                  {/* <img
                    src="assets/imgs/template/newsletter-left.png"
                    alt="joxBox"
                  /> */}
                </div>
                <div className="col-lg-12 col-xl-6 col-12">
                  <h2 className="text-md-newsletter text-center">
                    New Things Will Always
                    <br /> Update Regularly
                  </h2>
                  <div className="box-form-newsletter mt-40">
                    <form className="form-newsletter">
                      <input
                        className="input-newsletter"
                        type="text"
                        value=""
                        placeholder="Enter your email here"
                      />
                      <button className="btn btn-default font-heading icon-send-letter">
                        Subscribe
                      </button>
                    </form>
                  </div>
                </div>
                <div className="col-xl-3 col-12 text-center d-none d-xl-block">
                  {/* <img
                    src="assets/imgs/template/newsletter-right.png"
                    alt="joxBox"
                  /> */}
                </div>
              </div>
            </div>
          </div>
        </section>
        <script src="assets/js/plugins/counterup.js"></script>
      </main>
    </>
  );
};

export default Home;
