import { useNavigate } from "react-router-dom";

const Dashboard = () => {
  const navigate = useNavigate();

  return (
    <>
      <div className="d-flex align-items-center justify-content-center vh-100">
        <div className="text-center">
          <h1 className="display-3 fw-bold">Account Pending</h1>
          <p className="fs-4 mb-2">
            <span className="text-danger">
              Account verification is pending, Please wait for our team to contact you.
            </span>
          </p>
          {/* <p className="p-3">The page you’re looking for doesn’t exist.</p> */}
          <button
            className="btn btn-danger rounded-0"
            onClick={() => navigate("/")}
          >
            Log out
          </button>
        </div>
      </div>
    </>
  );
};

export default Dashboard;
