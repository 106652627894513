
import { useNavigate } from 'react-router-dom';

const Missing = () => {
            const navigate = useNavigate();

    return (
      <>
        <div className="d-flex align-items-center justify-content-center vh-100">
          <div className="text-center">
            <h1 className="display-1 fw-bold">404</h1>
            <p className="fs-3">
              <span className="text-danger">Oops!</span> Page not found.
            </p>
            <p className="p-3">The page you’re looking for doesn’t exist.</p>
            <button
              className="btn btn-dark rounded-0"
              onClick={() => navigate(-1)}
            >
              Go Back
            </button>
          </div>
        </div>
      </>
    );
}
 
export default Missing;