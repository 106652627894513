/* eslint-disable jsx-a11y/anchor-is-valid */

import { Link } from "react-router-dom";

/* eslint-disable jsx-a11y/anchor-has-content */
const Footer = () => {
  return (
    <>
      <footer className="footer mt-50">
        <div className="container">
          <div className="row">
            <div className="footer-col-8 col-md-4 col-sm-12">
              <Link to="/">
                <img alt="Link Jobs" src="assets/imgs/logo.png" />
              </Link>
              <div className="mt-20 mb-20 font-xs color-text-paragraph-2">
                Link Jobs is the heart of the design community and the best
                resource to discover and connect with designers and jobs in
                Uganda.
              </div>
              <div className="footer-social">
                <a
                  className="icon-socials icon-facebook"
                  href="https://facebook.com/linkjobsug"
                ></a>
                <a
                  className="icon-socials icon-twitter"
                  href="https://twitter.com/linkjobsug"
                ></a>
                <a
                  className="icon-socials icon-linkedin"
                  href="https://linkedln.com/linkjobsug"
                ></a>
              </div>
            </div>
            <div className="footer-col-6 col-md-4 col-xs-6">
              <h6 className="mb-20">Resources</h6>
              <ul className="menu-footer">
                <li>
                  <a href="#">About us</a>
                </li>
                <li>
                  <a href="#">Our Team</a>
                </li>
                <li>
                  <a href="#">Contact Us</a>
                </li>
              </ul>
            </div>
            <div className="footer-col-6 col-md-4 col-xs-6">
              <h6 className="mb-20">Community</h6>
              <ul className="menu-footer">
                <li>
                  <a href="#">Features</a>
                </li>
                <li>
                  <a href="#">Pricing</a>
                </li>
                <li>
                  <a href="#">FAQ</a>
                </li>
              </ul>
            </div>           
            <div className="footer-col-6 col-md-4 col-sm-12">
              <h6 className="mb-20">Download App</h6>
              <p className="color-text-paragraph-2 font-xs">
                Download our Apps and get extra 15% Discount on first subscription
              </p>
              <div className="mt-15">
                <a className="mr-5" href="#">
                  <img
                    src="assets/imgs/template/icons/app-store.png"
                    alt="joxBox"
                  />
                </a>
                <a href="#">
                  <img
                    src="assets/imgs/template/icons/android.png"
                    alt="joxBox"
                  />
                </a>
              </div>
            </div>
          </div>
          <div className="footer-bottom mt-50">
            <div className="row">
              <div className="col-md-6">
                <span className="font-xs color-text-paragraph">
                  Copyright &copy; 2005 - 2024. Link Jobs all right reserved
                </span>
              </div>
              <div className="col-md-6 text-md-end text-start">
                <div className="footer-social">
                  <a className="font-xs color-text-paragraph" href="#">
                    Privacy Policy
                  </a>
                  <a
                    className="font-xs color-text-paragraph mr-30 ml-30"
                    href="#"
                  >
                    Terms &amp; Conditions
                  </a>
                  <a className="font-xs color-text-paragraph" href="#">
                    Security
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </footer>
    </>
  );
};

export default Footer;
