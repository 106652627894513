import Header from "pages/components/header";
import Footer from "pages/components/footer";
import { Outlet } from "react-router-dom";
const LandingPageLayout = ({ children }) => {
  return (
    <>
      <Header />

      {children}
      <Outlet />

      <Footer />
    </>
  );
};

export default LandingPageLayout;
