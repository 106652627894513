import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useForm } from "react-hook-form";
import toast, { Toaster } from "react-hot-toast";
import useAuth from "middlewares/hooks/useAuth";

const Verify = () => {
  useEffect(() => {
    document.title = "Register - Link Jobs";
  }, []);
    const [disabled, setDisabled] = useState(false);
    const nagivate = useNavigate();
     const { auth } = useAuth();
    const {
      register,
      handleSubmit,
      formState: { errors },
    } = useForm();

    const onSubmit = async (data) => {
      try {
        setDisabled(true);
        if (data?.otp === auth.token){
          nagivate("/login");
        } else {
            setDisabled(false);
            toast("wrong otp code");
        }
      } catch (error) {
        console.log(error)
      }
    }; 
       const [timer, setTimer] = useState(60); // Initial timer set to 60 seconds (1 minute)
       const [resendDisabled, setResendDisabled] = useState(true);
       const [buttonDisabled, setButtonDisabled] = useState(false);

       useEffect(() => {
         let interval;
         if (timer > 0 && resendDisabled) {
           interval = setInterval(() => {
             setTimer((prevTimer) => prevTimer - 1);
           }, 1000);
         } else {
           setResendDisabled(false);
         }

         return () => clearInterval(interval);
       }, [timer, resendDisabled, buttonDisabled]);

       const handleResendClick = () => {
         setResendDisabled(true);
         setButtonDisabled(true); // Disable the button after it's clicked
         setTimer((prevTimer) => prevTimer + 60); // Add one minute (60 seconds) to the timer value
       };

       const formatTime = () => {
         const minutes = Math.floor(timer / 60);
         const seconds = timer % 60;
         return `${minutes < 10 ? "0" : ""}${minutes}:${
           seconds < 10 ? "0" : ""
         }${seconds}`;
       };
  return (
    <>
      <Toaster />
      <main className="main">
        <section className="login-register">
          <div className="container ">
            <div className="row login-register-cover">
              <div
                className="col-lg-4 col-md-6 col-sm-12 mx-auto vh-100"
                style={{ marginTop: "200px" }}
              >
                <div className="text-center">
                  {/* <p className="font-sm text-brand-2">Register </p> */}
                  <h2 className="mt-10 mb-5 text-brand-1">Verify Account</h2>
                  {/* <p className="font-sm text-muted mb-30">
                    Access to all features. No payment required.
                  </p> */}
                </div>
                <form
                  className="login-register text-start mt-0"
                  autoComplete="off"
                  onSubmit={handleSubmit(onSubmit)}
                >
                  <div className="form-group">
                    <label className="form-label" for="input-1">
                      Enter OTP Code *
                    </label>
                    <input
                      type="number"
                      className="form-control"
                      id="otp"
                      aria-describedby="otpcode"
                      placeholder="XXXXXX"
                      {...register("otp", { required: true })}
                    />
                    {errors?.otp?.type === "required" && (
                      <p className="text-small text-center text-danger help-block">
                        Please enter your verification otp from your email
                        address or contact
                      </p>
                    )}
                  </div>

                  <div className="form-group">
                    <button
                      className="btn btn-brand-1 hover-up w-100"
                      type="submit"
                      name="login"
                      disabled={disabled}
                    >
                      {!disabled && "Verify"} {disabled && "Please Wait ....."}
                    </button>
                  </div>
                  <div className="text-muted text-center">
                    <p className="p-1 w-100">
                      Resend Verification code in: {formatTime()}
                    </p>
                    <button
                      onClick={handleResendClick}
                      disabled={resendDisabled || buttonDisabled}
                      className="btn btn-sm btn-danger text-white"
                    >
                      Resend Verification Code
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </section>
      </main>
    </>
  );
};

export default Verify;
