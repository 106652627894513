import ProgressBar from "@badrap/bar-of-progress";
import useAuth from "middlewares/hooks/useAuth";
import axios from "config/axios";
import { useForm } from "react-hook-form";
import toast, { Toaster } from "react-hot-toast";
import { errorColor } from "services/colors/constants";
import { Link, useNavigate, useLocation } from "react-router-dom";
import { useState } from "react";

const Login = () => {
  const [remeber, setRemember] = useState(false);
  const onRemember = () => {
    remeber === true ? setRemember(false) : setRemember(true);
  };
  const progress = new ProgressBar({ color: "#020f46", size: 4 });
  const navigate = useNavigate();
  const location = useLocation();
  const { setAuth } = useAuth();
  const [disabled, setDisabled] = useState(false);
  const from = location.state?.from?.pathname || "/dashboard";
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();

  const onLoginAction = async (data) => {
    try {
      progress.start();
      setDisabled(true);
      const response = await axios.post("/auth/sessions", data);
      const accesstoken = response?.data?.data?.access_token;
      const refreshtoken = response?.data?.data?.refresh_token;
      const sessionid = response?.data?.data?.session_id;
      setAuth({ sessionid, accesstoken, refreshtoken });
      const verify = {
        refresh: refreshtoken,
        id: sessionid,
        access: accesstoken,
      };
      localStorage["verify"] = JSON.stringify(verify);
      navigate(from, { replace: true });
      progress.finish();
    } catch (error) {
      if (!error?.response) {
        setDisabled(false);
        toast.error("No server response", {
          style: { background: errorColor, color: "#000" },
        });
        progress.finish();
      } else {
        setDisabled(false);
        progress.finish();
        toast.error(error?.response?.data?.messages, {
          style: { background: errorColor, color: "#000" },
        });
      }
    }
  };
  return (
    <>
      <main className="main">
        <section className="pt-50 login-register">
          <div className="container">
            <div className="row login-register-cover">
              <div className="col-lg-4 col-md-6 col-sm-12 mx-auto">
                <div className="text-center">
                  <p className="font-sm text-brand-2">Welcome back! </p>
                  <h2 className="mt-10 mb-5 text-brand-1">Member Login</h2>
                  <p className="font-sm text-muted mb-30">
                    Access to all features. No Payment Required.
                  </p>
                  <button className="btn social-login hover-up mb-20">
                    <img
                      src="assets/imgs/template/icons/icon-google.svg"
                      alt="jobbox"
                    />
                    <strong>Sign in with Google</strong>
                  </button>
                  <div className="divider-text-center">
                    <span>Or continue with</span>
                  </div>
                </div>
                <form
                  className="login-register text-start mt-20"
                  action="#"
                  onSubmit={handleSubmit(onLoginAction)}
                >
                  <div className="form-group">
                    <label className="form-label" for="input-1">
                      Email address *
                    </label>
                    <input
                      className="form-control"
                      id="input-1"
                      type="text"
                      required=""
                      name="fullname"
                      placeholder="johndoe@example.com"
                      {...register("username", {
                        required: true,
                        pattern: { value: /^\S+@\S+$/i },
                      })}
                    />
                    {errors?.username?.type === "required" && (
                      <p className="text-small  m-0 text-danger help-block">
                        Please provide a valid username
                      </p>
                    )}
                  </div>
                  <div className="form-group">
                    <label className="form-label" for="input-4">
                      Password *
                    </label>
                    <input
                      className="form-control"
                      id="input-4"
                      type="password"
                      required=""
                      name="password"
                      placeholder="************"
                      {...register("password", { required: true })}
                    />
                    {errors?.password?.type === "required" && (
                      <p className="text-small m-0 text-danger help-block">
                        Please provide a password
                      </p>
                    )}
                  </div>
                  <div className="login_footer form-group d-flex justify-content-between">
                    <label className="cb-container">
                      <input type="checkbox" />
                      <span className="text-small">Remember me</span>
                      <span className="checkmark"></span>
                    </label>
                    <Link className="text-muted" to="/forgot-password">
                      Forgot Password
                    </Link>
                  </div>
                  <div className="form-group">
                    <button
                      className="btn btn-brand-1 hover-up w-100"
                      type="submit"
                      name="login"
                      disabled={disabled}
                    >
                      {!disabled && "Login"} {disabled && "Please Wait ....."}
                    </button>
                  </div>
                  <div className="text-muted text-center">
                    Don't have an Account? <Link to="/register">Register</Link>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </section>
      </main>
    </>
  );
};

export default Login;
