/* eslint-disable jsx-a11y/anchor-has-content */
/* eslint-disable jsx-a11y/anchor-is-valid */
import { useEffect } from "react";
import { keepPreviousData, useQuery } from "@tanstack/react-query";
import axios from "config/axios";
import Loader from "services/loaders/loader";
import { Link } from "react-router-dom";

const FindJobs = () => {
  useEffect(() => {
    document.title = "Jobs - Link Jobs";
  }, []);
  const { data: data_jobs, isLoading: isLoading_jobs } = useQuery({
    queryKey: ["jobs-data"],
    queryFn: async () => {
      const fetchURL = `/jobs`;

      try {
        const response = await axios.get(fetchURL);
        return response.data.data.jobs;
      } catch (error) {
        console.error("Axios request failed", error);
        throw error;
      }
    },
    placeholderData: keepPreviousData, // don't go to 0 rows when refetching or paginating to the next page
  });
  return (
    <>
      <main className="main">
        <section className="section-box-2">
          <div className="container">
            <div className="banner-hero banner-single banner-single-bg">
              <div className="block-banner text-center">
                <h3 className="wow animate__animated animate__fadeInUp">
                  <span className="color-brand-2">New Jobs</span> Available Now
                </h3>
                <div
                  className="font-sm color-text-paragraph-2 mt-10 wow animate__animated animate__fadeInUp"
                  data-wow-delay=".1s"
                >
                  Look at the latest Job Offers
                </div>
                <div
                  className="form-find text-start mt-40 wow animate__animated animate__fadeInUp"
                  data-wow-delay=".2s"
                >
                  <form>
                    <input
                      className="form-input input-keysearch mr-10"
                      type="text"
                      placeholder="Your keyword... "
                    />
                    <button className="btn btn-default btn-find font-sm">
                      Search
                    </button>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section className="section-box mt-30">
          <div className="container">
            <div className="row flex-row-reverse">
              <div className="col-lg-9 col-md-12 col-sm-12 col-12 float-right">
                <div className="content-page">
                  <div className="row display-list">
                    {isLoading_jobs && <Loader />}
                    {data_jobs?.map((job) => (
                      <div className="col-xl-12 col-12" key={job?.id}>
                        <div className="card-grid-2 hover-up">
                          <span className="flash"></span>
                          <div className="card-block-info pt-2">
                            <h4>
                              <Link to="/login">{job?.title}</Link>
                            </h4>
                            <div className="mt-5">
                              <span className="card-briefcase">Fulltime</span>
                            </div>
                            <p className="font-sm color-text-paragraph mt-10">
                              {job.designation}
                            </p>
                            <div className="card-2-bottom mt-20">
                              <div className="row">
                                <div className="col-lg-7 col-7">
                                  <span className="text-muted">Salary <br/></span>
                                  <span className="card-text-price">{job?.salary}</span>
                                </div>
                                <div className="col-lg-5 col-5 text-end">
                                  <Link
                                    className="btn btn-apply-now"
                                    to="/login"
                                  >
                                    Apply now
                                  </Link>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    ))}
                  </div>
                </div>
                <div className="paginations">
                  <ul className="pager">
                    <li>
                      <a className="pager-prev" href="#"></a>
                    </li>
                    <li>
                      <a className="pager-next" href="#"></a>
                    </li>
                  </ul>
                </div>
              </div>
              <div className="col-lg-3 col-md-12 col-sm-12 col-12">
                <div className="sidebar-shadow none-shadow mb-30">
                  <div className="sidebar-filters">
                    <div className="filter-block head-border mb-30">
                      <h5>
                        Filter{" "}
                        <a className="link-reset" href="#">
                          Reset
                        </a>
                      </h5>
                    </div>
                    <div className="filter-block mb-20">
                      <h5 className="medium-heading mb-15">Industry</h5>
                      <div className="form-group">
                        <ul className="list-checkbox">
                          <li>
                            <label className="cb-container">
                              <input type="checkbox" checked="checked" />
                              <span className="text-small">All</span>
                              <span className="checkmark"></span>
                            </label>
                            <span className="number-item">{data_jobs?.length}</span>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </main>
    </>
  );
};

export default FindJobs;
