/* eslint-disable jsx-a11y/anchor-has-content */
/* eslint-disable jsx-a11y/anchor-is-valid */
import { useEffect } from "react";
import { Link } from "react-router-dom";

const ContactUs = () => {
  useEffect(() => {
    document.title = "Contact Us - Link Jobs";
  }, []);

  return (
    <>
      <main className="main">
        <section className="section-box">
          <div className="breacrumb-cover bg-img-about">
            <div className="container">
              <div className="row">
                <div className="col-lg-6">
                  <h2 className="mb-10">Contact Us</h2>
                  <p className="font-lg color-text-paragraph-2">
                    Give us a call or wirte us an email
                  </p>
                </div>
                <div className="col-lg-6 text-lg-end">
                  <ul className="breadcrumbs mt-40">
                    <li>
                      <Link className="home-icon" to="/">
                        Home
                      </Link>
                    </li>
                    <li>Contact Us</li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section className="section-box mt-80">
          <div className="container">
            <div className="box-info-contact">
              <div className="row">
                <div className="col-lg-3 col-md-6 col-sm-12 mb-30">
                  <a href="#">
                    <img
                      src="assets/imgs/logo.png"
                      alt="joxBox"
                      height={"30px"}
                    />
                  </a>
                  <div className="font-sm color-text-paragraph">
                    Kampala, Uganda
                    <br /> Phone: (+256) 702 251 253
                    <br /> Email: info@linkjobsug.com
                  </div>
                </div>
                <div className="col-lg-3 col-md-6 col-sm-12 mb-30">
                  <h6>Masaka Branch</h6>
                  <p className="font-sm color-text-paragraph mb-20">
                    Nyendo Plaza One
                  </p>
                  <h6>Mbarara</h6>
                  <p className="font-sm color-text-paragraph mb-20">
                    GBK Plaza, Ntrare Road
                  </p>
                </div>
                <div className="col-lg-3 col-md-6 col-sm-12 mb-30">
                  <h6>Arua Branch</h6>
                  <p className="font-sm color-text-paragraph mb-20">
                    Opposite Arua Hills
                  </p>
                  <h6>Kabale Branch</h6>
                  <p className="font-sm color-text-paragraph mb-20">
                    Opposie Post Bus station
                  </p>
                </div>
                <div className="col-lg-3 col-md-6 col-sm-12 mb-30">
                  <h6>Jinja</h6>
                  <p className="font-sm color-text-paragraph mb-20">
                    Jinja City Mall
                  </p>
                  <h6>Isingiro</h6>
                  <p className="font-sm color-text-paragraph mb-20">
                    Kaberebere
                  </p>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section className="section-box mt-70">
          <div className="container">
            <div className="row">
              <div className="col-lg-8 mb-40">
                <span className="font-md color-brand-2 mt-20 d-inline-block">
                  Contact us
                </span>
                <h2 className="mt-5 mb-10">Get in touch</h2>
                <p className="font-md color-text-paragraph-2">
                  The right move at the right time saves your investment. live
                  <br className="d-none d-lg-block" /> the dream of expanding
                  your business.
                </p>
                <form
                  className="contact-form-style mt-30"
                  id="contact-form"
                  action="#"
                  method="post"
                >
                  <div
                    className="row wow animate__animated animate__fadeInUp"
                    data-wow-delay=".1s"
                  >
                    <div className="col-lg-6 col-md-6">
                      <div className="input-style mb-20">
                        <input
                          className="font-sm color-text-paragraph-2"
                          name="name"
                          placeholder="Enter your name"
                          type="text"
                        />
                      </div>
                    </div>
                    <div className="col-lg-6 col-md-6">
                      <div className="input-style mb-20">
                        <input
                          className="font-sm color-text-paragraph-2"
                          name="company"
                          placeholder="Comapy (optioanl)"
                          type="text"
                        />
                      </div>
                    </div>
                    <div className="col-lg-6 col-md-6">
                      <div className="input-style mb-20">
                        <input
                          className="font-sm color-text-paragraph-2"
                          name="email"
                          placeholder="Your email"
                          type="email"
                        />
                      </div>
                    </div>
                    <div className="col-lg-6 col-md-6">
                      <div className="input-style mb-20">
                        <input
                          className="font-sm color-text-paragraph-2"
                          name="phone"
                          placeholder="Phone number"
                          type="tel"
                        />
                      </div>
                    </div>
                    <div className="col-lg-12 col-md-12">
                      <div className="textarea-style mb-30">
                        <textarea
                          className="font-sm color-text-paragraph-2"
                          name="message"
                          placeholder="Tell us about yourself"
                        ></textarea>
                      </div>
                      <button
                        className="submit btn btn-send-message"
                        type="submit"
                      >
                        Send message
                      </button>
                      <label className="ml-20">
                        <input
                          className="float-start mr-5 mt-6"
                          type="checkbox"
                        />{" "}
                        By clicking contact us button, you agree our terms and
                        policy,
                      </label>
                    </div>
                  </div>
                </form>
                <p className="form-messege"></p>
              </div>
              <div className="col-lg-4 text-center d-none d-lg-block">
                <img
                  src="https://external-content.duckduckgo.com/iu/?u=http%3A%2F%2Fblogs.worldbank.org%2Fsites%2Fdefault%2Ffiles%2Fjobs%2F21979640499_3a8a02b0fe_o.jpg&f=1&nofb=1&ipt=bccb09712f45ecad18e51a658201926e7ec0b3af9692ca49a0f0e59e98a6e70b&ipo=images"
                  alt="joxBox"
                />
              </div>
            </div>
          </div>
        </section>
      </main>
    </>
  );
};

export default ContactUs;
